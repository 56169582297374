<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-8">
								<h5 class="mb-4">
									<strong>Categorías de Productos - Listado General</strong>
								</h5>
							</div>
							<div class="col-md-4 text-right">
								<a-button class="btn btn-info" icon="plus" @click="onAddRecord('category')">Añadir</a-button>
								<a-button class="btn btn-success ml5" icon="reload" @click="onLoadCategories" />
							</div>
							<div class="col-md-12">
								<hr class="hrText" />
							</div>
						</div>
						<a-table :columns="categoryColumns" :dataSource="productCategoriesList" rowKey="id">
							<div slot="image" slot-scope="record">
								<a :href="`${apiURL}/${record.image}`" target="new" v-if="!!record.image">
									<img :src="`${apiURL}/${record.image}`" height="36" />
								</a>
								<img src="resources/images/products/defaultCategory.png" height="36" v-else />
							</div>
							<span slot="status" slot-scope="record">
								<a-tag color="green" v-if="record.active == 1">Activo</a-tag>
								<a-tag color="red" v-else>Inactivo</a-tag>
							</span>
							<span slot="action" slot-scope="text, record">
								<button class="ml5 btn btn-info ant-btn-sm ant-btn-icon-only" @click="uploadImage(record, 'category')">
									<a-icon type="picture" />
								</button>
								<a-button class="ml5" type="info" size="small" icon="edit" @click="onEditRecord(record.id, 'category')" />
								<a-button class="ml5" size="small" icon="delete" type="danger" ghost @click="onDisableRecord(record.id, 'category')" />
							</span>
						</a-table>
					</div>
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-8">
								<h5 class="mb-4">
									<strong>Subcategorías de Productos - Listado General</strong>
								</h5>
							</div>
							<div class="col-md-4 text-right">
								<a-button class="btn btn-info" icon="plus" @click="onAddRecord('subcategory')">Añadir</a-button>
								<a-button class="btn btn-success ml5" icon="reload" @click="onLoadSubCategories" />
							</div>
							<div class="col-md-12">
								<hr class="hrText" />
							</div>
						</div>
						<a-table :columns="subcategoryColumns" :dataSource="productSubCategoriesList" rowKey="id" v-if="showSubcategories">
							<div slot="image" slot-scope="record">
								<a :href="`${apiURL}/${record.image}`" target="new" v-if="!!record.image">
									<img :src="`${apiURL}/${record.image}`" height="36" />
								</a>
								<img src="resources/images/products/defaultCategory.png" height="36" v-else />
							</div>
							<span slot="status" slot-scope="record">
								<a-tag color="green" v-if="record.active == 1">Activo</a-tag>
								<a-tag color="red" v-else>Inactivo</a-tag>
							</span>
							<span slot="action" slot-scope="text, record">
								<button class="ml5 btn btn-info ant-btn-sm ant-btn-icon-only" @click="uploadImage(record, 'subcategory')">
									<a-icon type="picture" />
								</button>
								<a-button class="ml5" type="info" size="small" icon="edit" @click="onEditRecord(record.id, 'subcategory')" />
								<a-button class="ml5" size="small" icon="delete" type="danger" ghost @click="onDisableRecord(record.id, 'subcategory')" />
							</span>
						</a-table>
					</div>
				</div>
			</div>
		</div>
		<a-modal :visible="modalImage.visible" width="500px" :footer="null" :title="modalImage.title" :closable="true" @cancel="handleCancelUpload">
			<productCategoriesComponent v-if="modalImage.visible" @success="handleSuccess" />
		</a-modal>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import productCategoriesComponent from '@/components/productCategories'

export default {
	name: 'productCategoriesView',
	components: {
		productCategoriesComponent,
	},
	data() {
		return {
			apiURL: process.env.VUE_APP_API_URL,
			modalImage: {
				visible: false,
				title: '',
				id: 0,
			},
			categoryColumns: [
				{
					title: '#',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Imagen',
					scopedSlots: { customRender: 'image' },
					align: 'center',
				},
				{
					title: 'Categoría',
					dataIndex: 'category_name',
					align: 'left',
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'status' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			subcategoryColumns: [
				{
					title: '#',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Imagen',
					scopedSlots: { customRender: 'image' },
					align: 'center',
				},
				{
					title: 'Categoría',
					dataIndex: 'category_name',
					filters: [],
					onFilter: (value, record) => record.category_name.indexOf(value) === 0,
				},
				{
					title: 'Subcategoría',
					dataIndex: 'subcategory_name',
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'status' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			showSubcategories: false,
		}
	},
	computed: {
		...mapGetters('products', ['productCategoriesList', 'productSubCategoriesList']),
		spinnerLoaderLabel() {
			return this.$store.state.products.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
	},
	mounted() {
		if (!this.productCategoriesList.length) {
			this.onLoadCategories()
		}
		if (this.productSubCategoriesList.length) {
			this.showSubcategories = true
		}
	},
	methods: {
		onLoadCategories() {
			this.$store.dispatch('products/GET_PRODUCT_CATEGORIES_LIST').then((response) => {
				this.subcategoryColumns[1].filters = response.map((e) => {
					return {
						text: e.category_name,
						value: e.category_name,
					}
				})
				if (!this.productSubCategoriesList.length) {
					this.onLoadSubCategories()
				}
			})
		},
		onLoadSubCategories() {
			this.showSubcategories = false
			this.$store.dispatch('products/GET_PRODUCT_SUBCATEGORIES_LIST').then(() => {
				this.showSubcategories = true
			})
		},
		onAddRecord(type) {
			Swal.fire({
				title: type == 'category' ? 'Nueva categoría' : 'Nueva subcategoría',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: type == 'category' ? 'Guardar' : 'Continuar',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					if (!value) {
						return `Ingresa nombre de la ${type == 'category' ? ' categoría' : 'subcategoría'}`
					}
				},
			}).then((option) => {
				if (option.isConfirmed) {
					let payload = {
						value: option.value,
					}

					if (type == 'subcategory') {
						let inputCategories = {}

						this.productCategoriesList.map((element) => {
							inputCategories[element.id] = element.category_name
						})

						Swal.fire({
							title: 'Selecciona la categoría',
							input: 'select',
							inputOptions: {
								...inputCategories,
							},
							inputPlaceholder: 'Selecciona',
							showCancelButton: true,
							inputValidator: (value) => {
								if (!value) {
									return `Debes seleccionar una categoría asociada`
								}
							},
						}).then((option) => {
							//
							if (option.isConfirmed) {
								payload.category_id = option.value
								this.$store.dispatch(`products/CREATE_SUBCATEGORY`, payload)
							}
						})
					} else {
						//
						this.$store.dispatch(`products/CREATE_CATEGORY`, payload)
					}
				}
			})
		},
		onEditRecord(id, type) {
			let inputValue = ''
			if (type == 'category') {
				inputValue = this.productCategoriesList.find((element) => element.id == id).category_name
			} else {
				inputValue = this.productSubCategoriesList.find((element) => element.id == id).subcategory_name
			}

			Swal.fire({
				title: `Editar ${type == 'category' ? 'categoría' : 'subcategoría'}`,
				input: 'text',
				showCancelButton: true,
				inputValue,
				confirmButtonText: type == 'category' ? 'Guardar' : 'Continuar',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					if (!value) {
						return `Ingresa nombre de la ${type == 'category' ? ' categoría' : 'subcategoría'}`
					}
				},
			}).then((option) => {
				if (option.isConfirmed) {
					let payload = {
						id,
						value: option.value,
					}

					if (type == 'subcategory') {
						let inputCategories = {}

						this.productCategoriesList.map((element) => {
							inputCategories[element.id] = element.category_name
						})

						Swal.fire({
							title: 'Selecciona la categoría',
							input: 'select',
							inputOptions: {
								...inputCategories,
							},
							inputValue: this.productSubCategoriesList.find((element) => element.id == id).category_id,
							inputPlaceholder: 'Selecciona',
							showCancelButton: true,
							inputValidator: (value) => {
								if (!value) {
									return `Debes seleccionar una categoría asociada`
								}
							},
						}).then((option) => {
							//
							if (option.isConfirmed) {
								payload.category_id = option.value
								this.$store.dispatch(`products/UPDATE_SUBCATEGORY`, payload)
							}
						})
					} else {
						//
						this.$store.dispatch(`products/UPDATE_CATEGORY`, payload)
					}
				}
			})
		},
		onDisableRecord(id, type) {
			Swal.fire({
				title: 'Atención',
				text: '¿Desea deshabilitar el registro?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (type == 'category') {
						this.$store.dispatch('products/TOGGLE_CATEGORY', id)
					} else {
						this.$store.dispatch('products/TOGGLE_SUBCATEGORY', id)
					}
				}
			})
		},
		uploadImage(record, type) {
			this.$store.commit('products/SET_STATE', {
				actualProductCategory: _.cloneDeep(record),
			})
			this.modalImage = {
				title: type == 'category' ? 'Imagen representativa de categoría' : 'Imagen representativa de subcategoría',
				visible: true,
			}
		},
		handleCancelUpload() {
			this.modalImage.visible = false
		},
		handleSuccess(type) {
			this.modalImage.visible = false
			if (type == 'category') {
				this.onLoadCategories()
			} else {
				this.onLoadSubCategories()
			}
		},
	},
}
</script>

