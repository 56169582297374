<template>
	<div>
		<div class="row">
			<div class="col-md-12 text-center" v-if="!!categoryImage" style="padding-bottom: 20px">
				<a :href="categoryImage" target="new">
					<img class="img-fluid" :src="categoryImage" />
				</a>
			</div>
			<div class="col-md-12 text-right">
				<a-upload name="file" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload" :showUploadList="false" accept="image/*" @change="onChangeUpload">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<button class="btn btn-info ml5" v-if="!!categoryImage && fileList.length" @click="handleUpload">Subir imagen</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import axios from 'axios'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

export default {
	name: 'productCategoriesComponent',
	computed: {
		...mapGetters('products', ['actualProductCategory']),
	},
	data() {
		return {
			fileList: [],
			fileChanged: false,
			categoryImage: '',
			apiURL: process.env.VUE_APP_API_URL,
		}
	},
	beforeMount() {
		if (utilities.objectValidate(this.actualProductCategory, 'image', false)) {
			this.categoryImage = `${this.apiURL}/${this.actualProductCategory.image}`
		}
	},
	mounted() {
		const accessToken = store.get('accessToken')
		this.uploadHeaders = {
			Authorization: `Bearer ${accessToken}`,
		}
	},
	methods: {
		cleanData() {
			this.fileList = []
			this.fileChanged = false
			this.categoryImage = ''
		},
		handleRemove() {
			this.fileList = []
		},
		beforeUpload(file) {
			this.fileChanged = true
			this.fileList = [file]
			return false
		},
		handleUpload() {
			this.$store.commit('products/SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando archivo...',
			})
			const { fileList } = this
			const formData = new FormData()
			let url = ''

			formData.append('file', fileList[0])
			formData.append('id', this.actualProductCategory.id)

			if (utilities.objectValidate(this.actualProductCategory, 'category_id', false)) {
				url = `${this.apiURL}/api/products/subcategories/file`
			} else {
				url = `${this.apiURL}/api/products/categories/file`
			}

			axios
				.put(url, formData, { headers: this.uploadHeaders })
				.then((response) => {
					this.$store.commit('products/SET_STATE', {
						spinnerLoader: false,
					})

					this.fileList = []
					Swal.fire({
						title: 'Categorías de Productos',
						text: response.message || 'Datos actualizados con éxito',
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						if (utilities.objectValidate(this.actualProductCategory, 'category_id', false)) {
							this.$emit('success', 'subcategories')
						} else {
							this.$emit('success', 'category')
						}
					})
				})
				.catch((error) => {
					this.$store.commit('products/SET_STATE', {
						spinnerLoader: false,
					})

					Swal.fire({
						title: 'Categorías de Productos',
						text: 'Error al procesar el archivo adjunto',
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
		},
		onChangeUpload(event) {
			const reader = new FileReader()
			reader.readAsDataURL(event.file)
			reader.onload = () => {
				this.categoryImage = reader.result
			}
		},
	},
}
</script>